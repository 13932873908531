import { InfoService } from './../services/info.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Circuito } from 'src/app/models/circuito';

@Component({
  selector: 'app-circuitos',
  templateUrl: './circuitos.component.html',
  styleUrls: ['./circuitos.component.scss']
})
export class CircuitosComponent implements OnInit {

  circuitos: Circuito[];

  displayCircuitos: Circuito[] = [];

  constructor(private route: ActivatedRoute, private infos: InfoService) { }

  ngOnInit() {
    this.infos.getCircuitos().subscribe((res: Circuito[]) => {
    this.circuitos = res;
    const type = this.route.snapshot.url[0].path;
    this.displayCircuitos = this.circuitos.filter(circ => circ.destino === type);
    });
  }

}
