import { InfoService } from './../services/info.service';
import { Component, OnInit } from '@angular/core';
import { Cruzeiro } from 'src/app/models/cruzeiro';

@Component({
  selector: 'app-cruzeiros',
  templateUrl: './cruzeiros.component.html',
  styleUrls: ['./cruzeiros.component.scss']
})
export class CruzeirosComponent implements OnInit {

  cruzeiros: Cruzeiro[];

  constructor(private infos: InfoService) { }

  ngOnInit() {
    this.infos.getCruzeiros().subscribe((res: Cruzeiro[]) => {
      this.cruzeiros = res;
    });
  }

}
