import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircuitosComponent } from './circuitos.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule
  ],
  declarations: [CircuitosComponent],
  exports: [CircuitosComponent]
})
export class CircuitosModule { }
