import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss']
})
export class ModalImageComponent {

  @Input() img;

  constructor(private activeModal: NgbActiveModal) { }

  modalClose() {
    this.activeModal.close();
  }

}
