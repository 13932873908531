import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalImageComponent } from '../modal-image/modal-image.component';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-hoteis-modal',
  templateUrl: './hoteis-modal.component.html',
  styleUrls: ['./hoteis-modal.component.scss']
})
export class HoteisModalComponent implements OnInit {

  @Input() hotel;

  optionOn: number = 0;
  videoUrl: SafeResourceUrl;
  mapUrl: SafeResourceUrl;

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.hotel.video) {
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.hotel.video);
    }
    if (this.hotel.mapa) {
      this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.hotel.mapa);
    }
  }

  changeOption(opt: number) {
    this.optionOn = Number(opt);
  }

  modalOpen(img) {
    const imageModal = this.modalService.open(ModalImageComponent, { size: 'xl', windowClass: 'modal-holder', centered: true });
    imageModal.componentInstance.img = img;
  }

  modalClose() {
    this.activeModal.close();
  }
}

