import { ContactsComponent } from './contacts/contacts.component';
import { CircuitosComponent } from './circuitos/circuitos.component';
import { HoteisComponent } from './hoteis/hoteis.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PromocoesComponent } from './promocoes/promocoes.component';
import { CruzeirosComponent } from './cruzeiros/cruzeiros.component';


const routes: Routes = [
  { path: 'promo', component: PromocoesComponent },
  { path: 'voohotel', component: HoteisComponent },
  { path: 'circuitos', children: [
    { path: 'europa', component: CircuitosComponent },
    { path: 'asia', component: CircuitosComponent },
    { path: 'americas', component: CircuitosComponent }
    // { path: 'africa', component: CircuitosComponent }
  ] },
  { path: 'cruzeiros', component: CruzeirosComponent },
  { path: 'contactos', component: ContactsComponent },
  { path: '**', component: PromocoesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
