import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CruzeirosComponent } from './cruzeiros.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule
  ],
  declarations: [CruzeirosComponent],
  exports: [CruzeirosComponent]
})
export class CruzeirosModule { }
