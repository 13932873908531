import { Component, ViewChild, OnInit } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('circuitos', { static: false }) circuitos: NgbDropdown;


  title = 'Turistour';
  canClose = false;
  isToogled = true;

  year: number;

  ngOnInit() {
    this.year = new Date().getFullYear();
  }

  aa() {
    const tOut = setInterval(_ => {
      if (this.canClose) {
        this.circuitos.close();
        this.canClose = false;
        clearTimeout(tOut);
      }
    }, 1000);
  }

  isHovering() {
    this.canClose = false;
  }

  isLeaving() {
    this.canClose = true;
  }
}
