import { ModalImageComponent } from './modal-image/modal-image.component';
import { HoteisModalComponent } from './hoteis-modal/hoteis-modal.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoteisComponent } from './hoteis.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    NgbModule
  ],
  declarations: [HoteisComponent, HoteisModalComponent, ModalImageComponent],
  entryComponents:[HoteisModalComponent, ModalImageComponent],
  exports: [HoteisComponent]
})
export class HoteisModule { }
