import { InfoService } from './../services/info.service';
import { HoteisModalComponent } from './hoteis-modal/hoteis-modal.component';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Hotel } from 'src/app/models/hotel';

@Component({
  selector: 'app-hoteis',
  templateUrl: './hoteis.component.html',
  styleUrls: ['./hoteis.component.scss']
})
export class HoteisComponent implements OnInit {

  hoteis: Hotel[];

  constructor(private modalService: NgbModal, private infos: InfoService) { }

  ngOnInit() {
    this.infos.getHoteis().subscribe((res: Hotel[]) => this.hoteis = res);
  }

  modalOpen(hotel) {
    const hotelModal = this.modalService.open(HoteisModalComponent, { size: 'lg', windowClass: 'modal-holder', centered: true });
    hotelModal.componentInstance.hotel = hotel;
  }
}
