import { SliderItem } from './../models/sliderItem';
import { HoteisModalComponent } from './../hoteis/hoteis-modal/hoteis-modal.component';
import { Circuito } from 'src/app/models/circuito';
import { Hotel } from 'src/app/models/hotel';
import { Promocao } from 'src/app/models/promocao';
import { InfoService } from './../services/info.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { NgbSlideEvent, NgbCarousel, NgbSlideEventSource, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-promocoes',
  templateUrl: './promocoes.component.html',
  styleUrls: ['./promocoes.component.scss']
})
export class PromocoesComponent implements OnInit {

  promocoes: Promocao[] = [];

  sliderItems: SliderItem[];

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  constructor(private infos: InfoService, private modalService: NgbModal) { }

  ngOnInit() {

    this.infos.getSliderItem().subscribe((res: SliderItem[]) => this.sliderItems = res);

    this.infos.getPromos().subscribe((res: Array<any>) =>

      res.forEach(element =>
        this.promocoes = this.promocoes.concat(element.filter(elem => elem.promocao))
      )
    );
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  cardClicked(promo: Hotel | Circuito) {
    if (promo['estrelas']) {
      const hotelModal = this.modalService.open(HoteisModalComponent, { size: 'lg', windowClass: 'modal-holder', centered: true });
      hotelModal.componentInstance.hotel = promo;
    }
    if (promo['ficheiro']) {
      window.open(`assets/pdf/${promo['ficheiro']}`, '_blank');
    }
  }

}
