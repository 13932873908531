import { Cruzeiro } from './../models/cruzeiro';
import { Circuito } from 'src/app/models/circuito';
import { Hotel } from 'src/app/models/hotel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Promocao } from 'src/app/models/promocao';
import { SliderItem } from '../models/sliderItem';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  readonly BASE_URL = './assets/models/';

  constructor(private http: HttpClient) {
  }

  getHoteis(): Observable<Hotel[]> {
    return this.http.get(this.BASE_URL + 'hoteis.json').pipe(catchError(error => this.handleError(error)));
  }

  getCircuitos(): Observable<Circuito[]> {
    return this.http.get(this.BASE_URL + 'circuitos.json').pipe(catchError(error => this.handleError(error)));
  }

  getCruzeiros(): Observable<Cruzeiro[]> {
    return this.http.get(this.BASE_URL + 'cruzeiros.json').pipe(catchError(error => this.handleError(error)));
  }

  getSliderItem(): Observable<SliderItem[]> {
    return this.http.get(this.BASE_URL + 'slider.json').pipe(catchError(error => this.handleError(error)));
  }

  getPromos(): Observable<Promocao[]> {
    return forkJoin(
      this.http.get(this.BASE_URL + 'hoteis.json'),
      this.http.get(this.BASE_URL + 'circuitos.json'),
      this.http.get(this.BASE_URL + 'cruzeiros.json')
    ).pipe(
      catchError(error => this.handleError(error)
      ));
  }

  private handleError(result) {
    alert('ERROR: REQUEST WENT WRONG');
    return [];
  }

}
