import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromocoesComponent } from './promocoes.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    SlickCarouselModule
  ],
  declarations: [PromocoesComponent],
  exports: [PromocoesComponent]
})
export class PromocoesModule { }
