import { InfoService } from './services/info.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CruzeirosModule } from './cruzeiros/cruzeiros.module';
import { HoteisModule } from './hoteis/hoteis.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CircuitosModule } from './circuitos/circuitos.module';
import { PromocoesModule } from './promocoes/promocoes.module';
import { ContactsComponent } from './contacts/contacts.component';

@NgModule({
   declarations: [
      AppComponent,
      ContactsComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      NgbModule,
      HoteisModule,
      CircuitosModule,
      PromocoesModule,
      CruzeirosModule,
      HttpClientModule
   ],
   providers: [
      HttpClient,
      InfoService
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
